import { Box } from '@trmediaab/zebra';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import Ad from '~/components/Ad';
import Footer from '~/components/Footer';
import Navbar from '~/components/Navbar';
import PageMeta, { defaultMeta } from '~/components/PageMeta';
import Promo from '~/containers/Promo';

import routesMap from '../../main/routes';
import Logobar from './Logobar';
import Sidebar from './Sidebar';
import { AppWrapper, Aside, Main, PageContainer } from './styles';

class Page extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  render() {
    const { location } = this.props;
    const route = routesMap[location.type];
    const {
      pageBackgroundVariant,
      pageVariant,
      panoramaEnabled,
      component: PageComponent,
    } = route;

    return (
      <AppWrapper role="document" backgroundVariant={pageBackgroundVariant}>
        <PageMeta meta={defaultMeta}>
          <link
            rel="canonical"
            href={`https://travrondenspel.se${location.pathname}`}
          />
        </PageMeta>

        {pageVariant !== 'stripped' && <Navbar />}
        {pageVariant === 'stripped' && <Logobar />}

        <Promo />

        {panoramaEnabled && (
          <Ad
            deviceFilter="desktopOnly"
            placement="panorama"
            refreshKey={`desktop_${location.pathname}`}
            wrap={content => (
              <Box backgroundColor="#e1e1e1" padding="20px 0 30px">
                {content}
              </Box>
            )}
          />
        )}

        <PageContainer>
          <Main hasPanorama={panoramaEnabled}>
            {panoramaEnabled && (
              <Ad
                deviceFilter="mobileOnly"
                placement="panorama"
                refreshKey={`mobile_${location.pathname}`}
                wrap={content => (
                  <Box mb={location.type !== 'HOME' ? '24px' : undefined}>
                    {content}
                  </Box>
                )}
              />
            )}
            <PageComponent location={location} />
          </Main>
          {pageVariant !== 'stripped' && (
            <Aside hasPanorama={panoramaEnabled}>
              <Sidebar location={location} />
            </Aside>
          )}
        </PageContainer>

        <Footer />
      </AppWrapper>
    );
  }
}

const mapStateToProps = state => ({
  location: state.location,
});

export default connect(mapStateToProps)(Page);
