import { all, put, select, takeLatest } from 'redux-saga/effects';

import { INIT_APP } from '~/containers/Auth/actions';
import { fetchGeneric } from '~/containers/GenericFetch/saga';
import { LOAD_ROUND_START } from '~/containers/RoundTipStart/constants';
import { apiUrl } from '~/utils/request';
import { subscribe } from '~/utils/websocket';

import { NAMESPACE } from './constants';
import { selectRound } from './selectors';

export function* getRoundBySlug({ payload: { slug } }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`round/${slug}`),
    maxAge: Number.POSITIVE_INFINITY,
    key: slug,
  });

  const round = yield select(selectRound, { slug });

  if (round) {
    yield put(subscribe(`round:${round.id}:travronden_spel`));
  }
}

export function* getActiveRounds() {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl('round/active'),
    key: 'active',
  });
}

export function* getLiveRounds() {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl('round/live'),
    key: 'live',
  });
}

export function* getRoundResult({ payload: { slug } }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`round/${slug}/results`),
    maxAge: Number.POSITIVE_INFINITY,
    key: `result-${slug}`,
  });
}

export function* getRoundResults({ payload: { bet_type } }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`round/results`, { bet_type }),
    maxAge: Number.POSITIVE_INFINITY,
    key: `results-${bet_type}`,
  });
}

export default function* rootSaga() {
  yield all([
    takeLatest(['ROUND_TIP', LOAD_ROUND_START], getRoundBySlug),
    takeLatest('ROUND_RESULT', getRoundResult),
    takeLatest('ROUND_RESULTS', getRoundResults),
    takeLatest(INIT_APP, getActiveRounds),
    takeLatest(INIT_APP, getLiveRounds),
  ]);
}
