import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchGeneric } from '~/containers/GenericFetch/saga';
import { formatDate } from '~/utils/format';
import { apiUrl } from '~/utils/request';
import { subscribe } from '~/utils/websocket';

import { NAMESPACE } from './constants';
import { selectRace } from './selectors';

export function* getRaces({ payload }) {
  let date = payload.date || formatDate(new Date(), 'yyyy-MM-dd');
  if (payload.slug) {
    date = payload.slug.slice(-10);
  }

  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl('race', { ordering: 'start_time', limit: 200, date }),
    maxAge: Number.POSITIVE_INFINITY,
    responseHandler: resp => resp.results,
    key: `race_list_${date}`,
  });
}

export function* getRaceBySlug({ payload: { race_number, date, track } }) {
  const slug = `${track}-${date}`;

  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`race/${slug}-${race_number}`),
    maxAge: Number.POSITIVE_INFINITY,
    key: `${slug}-${race_number}`,
  });

  const race = yield select(selectRace, { slug, race_number });

  if (race) {
    yield put(subscribe(`race:${race.id}:travronden_spel`));

    if (race.has_result) {
      yield call(getRaceResults, {
        date: race.meet.race_date,
        track: race.meet.track_slug,
        race_number: race.race_number,
      });
    }
  }
}

export function* getRaceResults({ date, track, race_number }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`race/${track}-${date}-${race_number}/results`),
    key: `results-${track}-${date}-${race_number}`,
  });
}

export default function* rootSaga() {
  yield all([
    takeLatest('RACE_LIST', getRaces),
    takeLatest('RACE', getRaces),
    takeLatest('RACE', getRaceBySlug),
    takeLatest('ROUND_RESULT', getRaces),
  ]);
}
