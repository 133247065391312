import {
  Anchor,
  Box,
  Button,
  Divider,
  FlexBox,
  Position,
  Rhythm,
  Text,
} from '@trmediaab/zebra';
import { Chevron } from '@trmediaab/zebra-icons';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import Link from 'redux-first-router-link';
import { css } from 'styled-components';

import { getLoginUrl } from '~/containers/Auth/utils';
import media from '~/utils/media';

import { TABLET_BREAKPOINT } from '../constants';
import { userDropDownItems } from '../items';

const arrowAfterStyles = css`
  &::after {
    bottom: 100%;
    right: 40px;
    border-style: solid;
    border-image: initial;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgb(255 255 255 / 0%) rgb(255 255 255 / 0%) rgb(255 255 255);
    border-width: 12px;
    margin-left: -12px;

    ${media.max(TABLET_BREAKPOINT)`
        display:none;
    `}
  }
`;

const UserItems = ({ user, hasPremium }) => {
  const [accountOpen, setAccountOpen] = useState(false);
  const userTitle =
    [user?.first_name, user?.last_name].filter(Boolean).join(' ') || 'Konto';

  const loggedOutButtons = (
    <Box ml="auto">
      <Anchor as={Link} to={{ type: 'PRODUCTS_PAGE' }}>
        <Button
          ml={['auto', null, '2']}
          bg="blue"
          color="white"
          borderRadius="1"
          p={(4, 2)}
          fontSize={[0, null, 1]}
        >
          Prenumerera
        </Button>
      </Anchor>
      <Anchor ml="2" href={getLoginUrl()}>
        <Button
          ml={['auto', null, '2']}
          bg="greys.1"
          color="primary"
          borderRadius="1"
          p={(4, 2)}
          fontSize={[0, null, 1]}
        >
          Logga in
        </Button>
      </Anchor>
    </Box>
  );

  const LinkItem = props => (
    <Anchor
      variant="matchText"
      fontWeight="bold"
      fontSize="1"
      display="block"
      as={Link}
      onClick={() => {
        setAccountOpen(false);
      }}
      {...props}
    />
  );

  return (
    <Box bg={['white', null, 'transparent']} width={['100%', 'auto']} ml="auto">
      <FlexBox p={['2', null, '0']} alignItems="center" width="100%">
        <Text.p
          fontSize={[0, 1]}
          color={['black', null, 'white']}
          display={['block', null, user ? null : 'none', 'block']}
        >
          Vi gör dig till en vinnare
        </Text.p>

        {!user && loggedOutButtons}

        {user && (
          <Button
            ml={['auto', null, '2']}
            borderRadius="1"
            bg={'greys.1'}
            onClick={() => setAccountOpen(!accountOpen)}
            p="2"
          >
            <FlexBox alignItems="center">
              <Text
                color="primary"
                fontWeight="semiBold"
                fontSize={[0, null, 2]}
              >
                Mitt konto
              </Text>
              <Chevron
                direction={!accountOpen ? 'down' : 'up'}
                width="16px"
                height="16px"
              />
            </FlexBox>
          </Button>
        )}
      </FlexBox>

      {accountOpen && (
        <Fragment>
          <Position.fixed
            display={['none', null, 'block']}
            width="100%"
            height="100%"
            top="0"
            left="0"
            zIndex="300"
            onClick={() => setAccountOpen(false)}
            css={`
              background-color: rgba(0, 0, 0, 0.3);
            `}
          />
          <Position
            display="flex"
            justifyContent="center"
            flexDirection="column"
            width="100%"
            bg="greys.0"
            pt="2"
            pb="2"
            top={60}
            position={['static', null, 'absolute']}
            zIndex="9999"
            css={`
              ${arrowAfterStyles}
            `}
          >
            <Box pl="3" pb="1">
              <Text fontSize="4" fontWeight="bold">
                {userTitle}
              </Text>
              <Text fontSize="0">
                {hasPremium ? (
                  'Abonnemang: Travronden Digital'
                ) : (
                  <>
                    Gratiskonto -{' '}
                    <Anchor fontWeight="semiBold" as={Link} href="/prenumerera">
                      Prenumerera
                    </Anchor>
                  </>
                )}
              </Text>
            </Box>
            <Rhythm between="2" mt="2" mb="2">
              {userDropDownItems.map((array, index) => (
                <Box key={index}>
                  <Divider mb="2" />
                  <Rhythm pl="3" between="3">
                    {array.map(({ href, to, text, external }) =>
                      href ? (
                        <LinkItem
                          href={href}
                          key={text}
                          target={external ? '_blank' : '_self'}
                          as="a"
                        >
                          {text}
                        </LinkItem>
                      ) : (
                        <LinkItem to={to} key={text}>
                          {text}
                        </LinkItem>
                      ),
                    )}
                  </Rhythm>
                </Box>
              ))}
            </Rhythm>
          </Position>
        </Fragment>
      )}
    </Box>
  );
};

UserItems.propTypes = {
  user: PropTypes.object,
  hasPremium: PropTypes.bool,
};

export default UserItems;
