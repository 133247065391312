import { css } from 'styled-components';

import media from '~/utils/media';

export function clearFix() {
  return `
    &::after {
      display: table;
      content: "";
      clear: both;
    }
  `;
}

export const navHeights = {
  topRow: 45,
  middleRow: 45,
  middleRowTabletUp: 75,
  lowerRow: 45,
};

export function stickyStyles(offset = 0) {
  return css`
    position: sticky;
    top: ${navHeights.middleRow + offset}px;

    ${media.desktop`
      top: ${navHeights.middleRowTabletUp + navHeights.lowerRow + offset}px;
    `}
  `;
}

export const colors = {
  primary: '#090A3A' /** Dark blue */,
  black: '#000',
  bluelight: '#358bcb',
  blue: '#007cd5',
  bluedark: '#002349',
  lightgrey: '#999',
  yellow: '#f5c00c',
  orange: '#ff431d',
  red: '#f22b2a',
  change: '#d20000',
  white: '#fff',
  bgsite: '#fff',
  bgsiteSecondary: '#F1F1F1',
  bgsitecomplementary: '#f6f4f3',
  bgdark: '#191c22',
  bginfo: '#fff7c7',
  bginverted: '#f2f2f2',
  bodyText: '#222222',
  border: '#ddd',
  borderdark: '#4a4a4a',
  buttonprimary: '#007cd5',
  text: '#000',
  textmeta: '#888',
  textsuccess: '#270',
  textfail: '#d8000c',
  textlight: '#fff',
  link: '#3E78B2',
  linkactive: '#007cd5',
  travrondenred: '#f41f16',
};

export const fonts = {
  primary: [
    'Open Sans',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica',
    'Helvetica Neue',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ].join(', '),
  secondary: [
    'GuardianEgyptian',
    'Constantia',
    'Lucida Bright',
    'Lucidabright',
    'Lucida Serif',
    'Lucida',
    'DejaVu Serif',
    'Bitstream Vera Serif',
    'Liberation Serif',
    'Georgia',
    'serif',
  ].join(', '),
};

export const spacing = {
  siteGutter: 15,
  siteContentWidth: '650px',
};

export const zIndex = {
  promo: 1001,
  subbar: 1005,
  navbar: 1010,
  dropdown: 9999,
  modal: 10_000,
};

export const layout = (() => {
  const maxWidthMain = 980; // Matches panorama ad format
  const asideWidth = 300; // Matches insider ad format
  const twoColGutters = { pageGutter: 20, columnGutters: { lg: 20, xl: 40 } };

  return {
    asideWidth,
    oneColumn: {
      maxWidth: maxWidthMain,
      pageGutter: spacing.siteGutter,
    },
    twoColumn: {
      ...twoColGutters,
      maxWidth:
        maxWidthMain +
        asideWidth +
        twoColGutters.columnGutters.xl +
        twoColGutters.pageGutter * 2,
    },
  };
})();

const betTypeColorsMap = {
  GS75: {
    from: '#2a513f',
    to: '#2a724e',
  },
  V64: {
    from: '#e95a0c',
    to: '#ef7c22',
  },
  V65: {
    from: '#a60d1f',
    to: '#cc2332',
  },
  V75: {
    from: '#1e3480',
    to: '#0d57a1',
  },
  V86: {
    from: '#711970',
    to: '#91448f',
  },
};

export const betTypeColors = betType =>
  betTypeColorsMap[betType] || {
    from: '#298dcd',
    to: '#80bde6',
  };

export const boxShadow = css`
  box-shadow: 0 2px 4px 0 rgb(185 185 185 / 50%);
  transition: box-shadow 0.3s ease;
`;

export const boxShadowHover = css`
  box-shadow: 0 6px 8px 0 rgb(185 185 185 / 50%);
`;
