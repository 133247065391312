import styled from 'styled-components';

import BaseLink from '~/components/Link';
import { colors, spacing, zIndex } from '~/utils/css-mixins';
import media from '~/utils/media';

export const StyledPromo = styled.div`
  position: fixed;
  left: 0;
  bottom: ${props => (props.loaded ? '0' : '-100%')};
  transition: bottom 0.7s ease;
  z-index: ${zIndex.promo};
  width: 100%;
  padding: ${spacing.siteGutter}px;
  background-color: ${colors.blue};
  color: ${colors.white};
  font-size: 12px;
  line-height: 20px;

  > div {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
  }

  ${media.tablet`
    > div {
      max-width: 450px;
    }
  `}

  ${media.desktop`
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    z-index: auto;

    > div {
      max-width: none;
      margin: 0;
    }
  `}
`;

export const PromoLink = styled(BaseLink)`
  display: block;
  text-decoration: underline;
  font-weight: 600;
  color: inherit;
  margin-top: 4px;

  ${media.desktop`
    display: inline-block;
    padding-left: 12px;
    margin-top: 0;
  `}
`;
