import { isEmpty } from 'lodash';
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { fetchGeneric } from '~/containers/GenericFetch/saga';

import routesMap from '../../main/routes';
import { apiUrl, request } from '../../utils/request';
import {
  fetchFeedFailure,
  fetchFeedStarted,
  fetchFeedSuccess,
} from './actions';
import { FETCH_OLDER_RESULTS, NEWS_NAMESPACE } from './constants';
import {
  getFeedFrom,
  getFeedList,
  getFeedTo,
  isLoadingLatestFeed,
  isLoadingOlderFeed,
} from './selectors';

const POLLING_START = 'feed/POLLING_START';
const POLLING_STOP = 'feed/POLLING_STOP';
const POLL_INTERVAL = 60 * 1000; // Poll once every minute
const TWENTY_FOUR_HOURS_IN_SECONDS = 24 * 60 * 60;

function* fetchExternalNews() {
  yield fetchGeneric({
    namespace: NEWS_NAMESPACE,
    url: apiUrl('content'),
    maxAge: '10minutes',
    key: 'latest',
    responseHandler: resp =>
      resp.results.map(item => ({
        id: item.id,
        time: item.publish_start,
        type: 'trNews',
        data: item,
      })),
    options: { headers: { SITE: 'travronden' } },
  });
}

function* fetchContentFeed(args) {
  const isOlderFetch = args != null;
  const isInitial = isEmpty(yield select(getFeedList));
  const loading = yield select(
    isOlderFetch ? isLoadingOlderFeed : isLoadingLatestFeed,
  );

  if (loading) {
    return;
  }

  const query = args || { from: yield select(getFeedTo) };

  if (isInitial || isOlderFetch) {
    yield put(fetchFeedStarted(isOlderFetch));

    try {
      const response = yield call(request, apiUrl(`content/feed`, query));
      yield put(fetchFeedSuccess(response));
    } catch (error) {
      yield put(fetchFeedFailure(error, isOlderFetch));
    }
  }
}

function* fetchOlderContentFeed() {
  const curFeeds = yield select(getFeedList);
  // Bail if we don't have the initial feed
  if (curFeeds.length === 0) {
    return;
  }

  const to = yield select(getFeedFrom);

  yield call(fetchContentFeed, {
    from: to - TWENTY_FOUR_HOURS_IN_SECONDS,
    to,
  });
}

function delay(duration) {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
  return promise;
}

function* poll() {
  try {
    while (true) {
      yield call(fetchContentFeed);
      yield call(delay, POLL_INTERVAL);
    }
  } finally {
    if (yield cancelled()) {
      import.meta.env.DEV && console.info('Poll loop cancelled');
    }
  }
}

function* onRouteChange({ type }) {
  yield type === 'HOME'
    ? put({ type: POLLING_START })
    : put({ type: POLLING_STOP });
}

export default function* rootSaga() {
  yield takeLatest(Object.keys(routesMap), onRouteChange);
  yield takeLatest('HOME', fetchExternalNews);
  yield takeEvery(FETCH_OLDER_RESULTS, fetchOlderContentFeed);

  while (yield take(POLLING_START)) {
    // Start the task in the background
    const pollingTask = yield fork(poll);
    // Wait for stop action
    yield take(POLLING_STOP);
    // This will throw a SagaCancellationException into the forked task
    yield cancel(pollingTask);
  }
}
