import { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectLive } from '~/containers/Round/selectors';

const mapStateToProps = createStructuredSelector({
  live: selectLive,
});

const RoundLink = WrappedComponent =>
  connect(mapStateToProps)(
    class extends Component {
      render() {
        const { live, ...props } = this.props;

        let to = props.to;

        if (
          live &&
          live.bet_type &&
          to.payload &&
          live.slug === to.payload.slug
        ) {
          to = {
            ...props.to,
            payload: {
              ...props.to.payload,
              view: 'live',
            },
          };
        }

        return <WrappedComponent {...props} to={to} />;
      }
    },
  );

export default RoundLink;
