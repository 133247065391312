import { takeEvery } from 'redux-saga/effects';

import { fetchGeneric } from '~/containers/GenericFetch/saga';

import { apiUrl } from '../../utils/request';
import { LOAD_START, NAMESPACE } from './constants';

export function* getStart({ id }) {
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl(`start/${id}`),
    maxAge: Number.POSITIVE_INFINITY,
    key: id,
  });
}

export default function* rootSaga() {
  yield takeEvery(LOAD_START, getStart);
}
