import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import media from '~/utils/media';

import ArrowIcon from './icons/arrow.svg';
import RoundList from './RoundList';
import { ARROW_STYLES, HEIGHT_MOBILE_DESKTOP } from './shared';

const Root = styled.div`
  width: 100%;
  background-color: #e1e1e1;
  overflow-y: hidden;
  border-bottom: 1px solid #e1e1e1;

  ${media.tablet`
   border-bottom: none;
  `}

  ${HEIGHT_MOBILE_DESKTOP}
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #e1e1e1;
  user-select: none;

  ${media.desktop`
   max-width: 1365px;
   margin: 0 auto;
  `};
`;

const StyledArrowLeft = styled.img`
  ${ARROW_STYLES}
  transform: rotate(180deg);

  ${props =>
    props.isLeft &&
    `
    opacity: 0.2;
    cursor:not-allowed;
  `}
`;

const StyledArrowRight = styled.img`
  ${ARROW_STYLES}

  ${props =>
    props.isRight &&
    `
    opacity: 0.2;
    cursor:not-allowed;
  `}
`;

const RoundNavigation = ({ closeMenu }) => {
  const MOVE_X = 600;
  const [xPosition, setxPosition] = useState(0);
  const [maxLeft, setMaxLeft] = useState(0);
  const [direction, setDirection] = useState(null);

  const handleArrowPress = useCallback(
    action => {
      if (action === 'minus') {
        if (xPosition - MOVE_X <= 0) {
          setxPosition(0);
        } else {
          setxPosition(xPosition - MOVE_X);
        }
      }

      if (action === 'plus') {
        if (xPosition + MOVE_X >= maxLeft) {
          setxPosition(maxLeft);
        } else {
          setxPosition(xPosition + MOVE_X);
        }
      }
      setDirection(true);
    },
    [xPosition, maxLeft],
  );

  return (
    <Root onClick={closeMenu}>
      <Wrapper>
        <StyledArrowLeft
          onClick={() => handleArrowPress('minus')}
          isLeft={xPosition === 0}
          src={ArrowIcon}
        />
        <RoundList
          xPosition={xPosition}
          setDirection={setDirection}
          direction={direction}
          setMaxLeft={setMaxLeft}
          setxPosition={setxPosition}
        />
        <StyledArrowRight
          src={ArrowIcon}
          onClick={() => handleArrowPress('plus')}
          isRight={maxLeft === xPosition}
        />
      </Wrapper>
    </Root>
  );
};

RoundNavigation.propTypes = {
  closeMenu: PropTypes.func,
};

export default RoundNavigation;
