import { put, takeLatest } from 'redux-saga/effects';

import { fetchGeneric } from '~/containers/GenericFetch/saga';
import { apiUrl } from '~/utils/request';

import { liveFeedVisit } from './actions';
import { NAMESPACE } from './constants';

function* getLiveFeed({ payload: { slug, view } }) {
  if (view === 'live') {
    yield put(liveFeedVisit(slug));
  }
  yield fetchGeneric({
    namespace: NAMESPACE,
    url: apiUrl('content/live', { round_slug: slug, limit: 200 }),
    maxAge: Number.POSITIVE_INFINITY,
    key: slug,
  });
}

export default function* rootSaga() {
  yield takeLatest('ROUND_TIP', getLiveFeed);
}
