import { css, keyframes } from 'styled-components';

import media from '~/utils/media';

export const ARROW_STYLES = css`
  @media (pointer: fine) {
    display: block;
  }

  cursor: pointer;
  padding: 0 3px;
  max-width: 26px;
  transition: 0.2s;

  &:hover {
    background-color: #cecece;
  }

  @media (pointer: coarse) {
    display: none;
    ${media.desktop`
      display:block;
    `}
  }
`;

export const HEIGHT_MOBILE_DESKTOP = css`
  height: 70px;

  ${media.desktop`
    height: 75px;
  `}
`;

export const LINEAR_ANIMATION = keyframes`
  0%{background-position: -475px 0}
  100%{background-position: 475px 0}
`;
